var rb = rb || {};
rb.main = {
    init: function () {
        this.initBS();
        this.initSwipebox();
        this.navbar();
        this.autosNavbar();
        this.inlineSVG();
        this.scrollToTop();
        this.fixWP();
        this.thumbToVideo();
    }

    ,initBS: function(){
        $('body').tooltip({
            container: 'body',
            selector: '[data-toggle="tooltip"]',
            animation : false
        });

    }

    ,initSwipebox: function(){
        $('.swipebox').swipebox({
            useCSS: true, // false will force the use of jQuery for animations
            useSVG: true, // false to force the use of png for buttons
            initialIndexOnArray: 0, // which image index to init when a array is passed
            hideCloseButtonOnMobile : false, // true will hide the close button on mobile devices
            removeBarsOnMobile : false, // false will show top bar on mobile devices
            hideBarsDelay : 0, // delay before hiding bars on desktop
            videoMaxWidth : 1140, // videos max width
            beforeOpen: function() {}, // called before opening
            afterOpen: function(){
                rb.main.inlineSVG();
            }, // called after opening
            afterClose: function() {}, // called after closing
            loopAtEnd: false // true will return to the first image after the last image is reached

        });
    }

    ,navbar: function(){
        // shrink on scroll
        $(window).on('scroll', function(){
            var wn = $(window).scrollTop(),
                $nav = $('body > nav');

            if(wn > 120){
                $nav.addClass('not-top');
            }else{
                $nav.removeClass('not-top');
            }
        });

        // scroll to target
        $('a.scroll-link, li.scroll-link a').click(function(e){
            var offset = 150,
                href = $(this).attr('href'),
                hash = href.substring(href.indexOf('#'));

            if (hash && $(hash).length){
                rb.main.scrollTo($(hash).offset().top - offset);
                e.preventDefault();
            }

            // close mobile-menu
            $('.navbar-collapse').collapse('hide');

        });

        // add class to navbar when collapse event is fired
        $('#navbar-menu').on('show.bs.collapse', function(){
            $('body>.navbar').addClass('menu-opened');
            $('<div class="modal-backdrop for-nav"></div>').appendTo(document.body);
        });
        $('#navbar-menu').on('hide.bs.collapse', function(){
            $('body>.navbar').removeClass('menu-opened');
            $('.modal-backdrop').remove();
        });

        if(window.location.hash && $(window.location.hash).length){
            var offset = 150,
                section = window.location.hash;
            rb.main.scrollTo($(section).offset().top - offset);
        }
    }

    ,autosNavbar: function(){
        var scrollDuration = 300,
            leftPaddle = document.getElementsByClassName('left-paddle'),
            rightPaddle = document.getElementsByClassName('right-paddle'),
            itemsLength = $('#automobiles-menu li').length,
            itemSize = $('#automobiles-menu li').outerWidth(true),
            paddleMargin = 20,
            getMenuWrapperSize = function() {
                // return $('#automobiles-menu').parent('.container-fluid').outerWidth();
                return $('#automobiles-menu').outerWidth();
            },
            menuWrapperSize = getMenuWrapperSize();

            $(window).on('resize', function() {
                menuWrapperSize = getMenuWrapperSize();
            });

        var menuVisibleSize = menuWrapperSize,
            getMenuSize = function() {
                return itemsLength * itemSize;
            },
            menuSize = getMenuSize(),
            menuInvisibleSize = menuSize - menuWrapperSize,
            getMenuPosition = function() {
                return $('#automobiles-menu').scrollLeft();
            },
            updatePaddles = function(){
                var menuPosition = getMenuPosition(),
                    menuEndOffset = menuInvisibleSize - paddleMargin;
                if (menuPosition <= paddleMargin) {
                    $(leftPaddle).addClass('hidden');
                    $(rightPaddle).removeClass('hidden');
                } else if (menuPosition < menuEndOffset) {
                    $(leftPaddle).removeClass('hidden');
                    $(rightPaddle).removeClass('hidden');
                } else if (menuPosition + $('#automobiles-menu').width() +20 >= $('#automobiles-menu')[0].scrollWidth) {
                    $(leftPaddle).removeClass('hidden');
                    $(rightPaddle).addClass('hidden');
                }
            };

        $('#automobiles-menu').on('scroll', function() {
            updatePaddles()
            // console.log(menuWrapperSize);
            // console.log(menuSize);
            // console.log(menuInvisibleSize);
            // console.log(menuPosition);

        });

        // scroll to left
        $(rightPaddle).on('click', function(e){
            var scroll_to = getMenuPosition() + (3 * itemSize);
            $('#automobiles-menu').animate({
                // scrollLeft: menuInvisibleSize
                scrollLeft: scroll_to
            }, scrollDuration);
            e.preventDefault();
        });

        // scroll to right
        $(leftPaddle).on('click', function(e){
            var scroll_to = getMenuPosition() - (3 * itemSize);
            $('#automobiles-menu').animate({
                // scrollLeft: '0'
                scrollLeft: scroll_to
            }, scrollDuration);
            e.preventDefault();
        });

        if ($('#automobiles-menu').length){
            $('#automobiles-menu').animate({
                scrollLeft: this.centerItVariableWidth('#automobiles-menu li.active', '#automobiles-menu')
            }, 1);
            setTimeout(updatePaddles, 100);
        }
    }

    ,inlineSVG: function(){

        var cache = this.svgCache = this.svgCache || {},
            $svgs = $('img[src$=".svg"]'),
            total = $svgs.length,
            count = 0;

        // If no SVGs on page, fire callback event
        if ( total === count ){
            $(document).trigger('svgsLoaded', [count]);
        }

        // define function to replace single svg
        var replaceSVG = function(data){

            // get img and attributes
            var $img = $(this),
                attributes = $img.prop('attributes');

            // Increment counter
            count++;

            // Clone the SVG tag, ignore the rest
            var $svg = $(data).find('svg').clone();

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Loop through IMG attributes and add them to SVG
            $.each(attributes, function() {
                $svg.attr(this.name, this.value);
            });

            // Replace image with new SVG
            $img.replaceWith($svg);

            // If this is the last svg, fire callback event
            if ( total === count ){
                $(document).trigger('svgsLoaded', [count]);
            }

        };

        // loop all svgs
        $svgs.each(function(){

            // get URL from this SVG
            var imgURL = $(this).attr('src');

            // if not cached, make new AJAX request
            if ( ! cache[imgURL] ){
                cache[imgURL] = $.get(imgURL).promise();
            }

            // when we have SVG data, replace img with data
            cache[imgURL].done( replaceSVG.bind(this) );

        });

    }

    ,scrollToTop: function(){
        var $win = $(window),
            $scrolltotop = $('#scrolltotop');

        $win.scroll(function(){
            var $this = $(this);
            if ($this.scrollTop() > 70 && $this.scrollTop() < 150){
                $scrolltotop.addClass('show-out');
            }else if ($this.scrollTop() >= 150) {
                $scrolltotop.removeClass('show-out').addClass('show');
            }else{
                $scrolltotop.removeClass('show-out show');
            }
        });
        $scrolltotop.click(function(){
            rb.main.scrollTo(0);
            return false;
        });

    }

    ,scrollTo: function(int){
        $('html, body').animate({
            scrollTop: parseInt(int)
        }, 500);
    }

    ,fixWP: function(){
        var $content = $('.content');

        // links that wrap images
        $content.find('a').has('img').addClass('img-wrap');

        // empty paragraphs
        $content.find('p').each(function(){
            var $this = $(this);
            if($this.html().replace(/\s| /g, '').length === 0) {
                $this.addClass('empty');
            }
        });
    }

    ,thumbToVideo: function(){
        var $video_wrapper = $('.video');

        $video_wrapper.click(function() {
            var $this = $(this),
                src = $this.attr('data-video'),
                parameters = '?rel=0&autoplay=1&modestbranding=0&showinfo=0&controls=1&color=white&iv_load_policy=3',
                content = '<div class="video-player embed-responsive embed-responsive-16by9">\
                              <iframe class="embed-responsive-item" src="' + src + parameters + '" frameborder="0" allowfullscreen></iframe>\
                            </div>';
            if (src){
                $video_wrapper.html(content);
            }
        });
    }

    ,centerItVariableWidth: function(target, outer){
        var out = $(outer);
        var tar = $(target);
        var x = out.width();
        var y = tar.outerWidth(true);
        var z = tar.index();
        var q = 0;
        var m = out.find('li');
        //Just need to add up the width of all the elements before our target.
        for(var i = 0; i < z; i++){
            q+= $(m[i]).outerWidth(true);
        }
        out.scrollLeft(Math.max(0, q - (x - y)/2));
    }
};

(function ($) {
    $(function () {
        rb.main.init()
    })
})(jQuery);
